<template>
  <div>
    <FileList :list="list" @onAdd="addFiles"></FileList>
    <input
        id="fileList"
        ref="fileList"
        :accept="'*/*'"
        class="custom-file-input"
        multiple
        type="file"
        @change="uploadNewFiles($event)"
    />
  </div>
</template>

<script>
import FileList from "@/components/FileList";

export default {
  name: "files",
  data() {
    return {
      list: [],
      bid: this.$route.params.bid,
      newFiles: null,
      inspection_id: "",
      inspection_type: "general",
      inspection_asset_type: "bridge",
    }
  },
  components: {
    FileList
  },
  created() {
    this.updateList()
  },
  methods: {
    async updateList() {
      const payload = {
        inspection_id: this.inspection_id,
        inspection_type: this.inspection_type,
        inspection_asset_type: this.inspection_asset_type,
        inspection_asset_id: this.bid,
        mode: "full"
      }
      this.list = await this.$store.dispatch("LOAD_BMS_INSPECTION_FILES", payload)
    },
    addFiles() {
      this.$refs.fileList.click()

    },
    async uploadNewFiles() {
      const files = event.target.files

      let formData = new FormData()
      formData.append('inspection_type', this.inspection_type)
      formData.append('inspection_id', this.inspection_id)
      formData.append('inspection_asset_type', this.inspection_asset_type)
      formData.append('inspection_element', "")
      formData.append('inspection_asset_id', this.bid)

      for (const i of Object.keys(files)) {
        formData.append('files', files[i])
      }

      try {
        await this.$store.dispatch('ADD_INSPECTION_FILE', formData)
        await this.updateList()
      } catch (err) {
        throw err
      }
    },
  }
}
</script>

<style lang="scss" scoped>
.custom-file-input {
  display: none;
}
</style>