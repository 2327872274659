<template>
  <div class="md-layout">
    <div class="md-layout-item">
      <div class="btn-row">
        <md-button class="md-success md-size-20 md-middle-size-100" @click=addFiles>
          {{ $t('button.add') }}
        </md-button>
      </div>
      <md-card>
        <md-card-header class="md-card-header-icon md-card-header-green">
          <div class="card-icon">
            <md-icon>source</md-icon>
          </div>
          <h4 class="title">{{ $t('label.files') }}</h4>

        </md-card-header>
        <md-card-content>
          <md-table
              :md-sort-fn="customSort"
              :md-sort-order.sync="currentSortOrder"
              :md-sort.sync="currentSort"
              :value="queriedData"
              class="paginated-table table-striped table-hover"
          >
            <md-table-toolbar>
              <md-field>
                <label for="pages">{{ $t('label.per_page') }}</label>
                <md-select v-model="pagination.perPage" name="pages">
                  <md-option
                      v-for="item in pagination.perPageOptions"
                      :key="item"
                      :label="item"
                      :value="item"
                  >
                    {{ item }}
                  </md-option>
                </md-select>
              </md-field>

              <md-field>
                <md-input
                    v-model="searchQuery"
                    :placeholder="$t('label.search_records')"
                    class="mb-3"
                    clearable
                    style="width: 200px"
                    type="search"
                ></md-input>
              </md-field>
            </md-table-toolbar>

            <md-table-row slot="md-table-row" slot-scope="{item}">
              <md-table-cell :md-label="$t('files.name')" md-sort-by="file_name">
                <a :href="item.file_url"><md-icon>link</md-icon></a> /
                {{ item.file_name }}
              </md-table-cell>
              <md-table-cell :md-label="$t('files.type')" md-sort-by="file_type">
                {{ item.file_type }}
              </md-table-cell>
              <md-table-cell :md-label="$t('files.size')" md-sort-by="file_size">
                {{ Math.round((item.file_size/1024/1024)*100)/100 }} Mb
              </md-table-cell>
              <md-table-cell :md-label="$t('files.created_at')" md-sort-by="created_at">
                {{ item.created_at | toDate }}
              </md-table-cell>
            </md-table-row>
          </md-table>
          <div class="footer-table md-table">
            <table>
              <tfoot>
              <tr>
                <th
                    v-for="item in footerTable"
                    :key="item.name"
                    class="md-table-head"
                >
                  <div
                      class="md-table-head-container md-ripple md-disabled"
                  >
                    <div class="md-table-head-label">{{ item }}</div>
                  </div>
                </th>
              </tr>
              </tfoot>
            </table>
          </div>
        </md-card-content>
        <md-card-actions md-alignment="space-between">
          <div class>
            <p class="card-category">
              {{
                $t('label.showing_from_to_of_entries', {
                  from: to === 0 ? 0 : from + 1,
                  to: to,
                  total
                })
              }}
            </p>
          </div>
          <pagination
              v-model="pagination.currentPage"
              :per-page="pagination.perPage"
              :total="total"
              class="pagination-no-border pagination-success"
          ></pagination>
        </md-card-actions>
      </md-card>
    </div>
  </div>
</template>

<script>
import {Pagination} from '@/components'

export default {
  name: "FileList",
  components: {Pagination},
  data() {
    return {
      pagination: {
        perPage: 10,
        currentPage: 1,
        perPageOptions: [5, 10, 25, 50],
        total: 0
      },
      currentSort: 'key',
      currentSortOrder: 'asc',
      searchQuery: '',
      footerTable: []
    }
  },
  props: {
    list: {
      type: Array, default() {
        return []
      }
    }
  },
  filters: {
    toDate: function (value) {
      return value ? value.substring(0, 10) + ' ' + value.substring(11, 19) : ""
    }
  },
  methods: {
    addFiles() {
      this.$emit("onAdd")
    },
    customSort(value) {
      return value.sort((a, b) => {
        const sortBy = this.currentSort
        if (this.currentSortOrder === 'asc') {
          return a[sortBy]
              .toString()
              .localeCompare(b[sortBy].toString(), undefined, {numeric: true})
        }
        return b[sortBy]
            .toString()
            .localeCompare(a[sortBy].toString(), undefined, {numeric: true})
      })
    }
  },
  computed: {
    queriedData() {
      const result = this.searchQuery
          ? this.searchedData
              ? this.searchedData
              : []
          : this.tableData
      return result.slice(this.from, this.to)
    },
    to() {
      let highBound = this.from + this.pagination.perPage
      if (this.total < highBound) {
        highBound = this.total
      }
      return highBound
    },
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1)
    },
    total() {
      return this.searchQuery ? this.searchedData.length : this.tableData.length
    },
    tableData() {
      return this.list
    }
  },
}
</script>

<style lang="scss" scoped>

.modified-percent {
  display: flex;
  width: 100%;
  align-items: center;
  padding: 0 5px;

  .md-progress-bar {
    width: 100%;
    margin: 4px 5px;
  }

  span {
    width: 4rem;
  }
}

.cost-overhead {
  color: red;
}

.cost-good {
  color: green;
}

.cell-actions {
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  .md-button {
    margin: 3px 0;
    min-width: 100px;
  }
}

.btn-row {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  flex-wrap: wrap;

  button {
    margin-left: 10px
  }
}
</style>